//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-180:_6604,_2632,_8576,_2209,_4320,_7840,_5580,_5108;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-180')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-180', "_6604,_2632,_8576,_2209,_4320,_7840,_5580,_5108");
        }
      }catch (ex) {
        console.error(ex);
      }